import React from "react"

import {
  Seo,
  Header,
  Layout,
  About,
  ContactForm,
  ReviewAndInsta,
  Gallery,
} from "components"

const IndexPage = () => (
  <Layout>
    <Seo />
    <Header />
    <About />
    <Gallery />
    <ReviewAndInsta />
    <ContactForm />
  </Layout>
)

export default IndexPage
